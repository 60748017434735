<template>
	<div class="cate">
		<div class="header">
			<van-search @change="reload" v-model="search.name" show-action placeholder="请输入搜索关键词" input-align="center">
				<template #action>
					<div @click="toUrl('/cate/add')" class="search">添加</div>
				</template>
			</van-search>
		</div>
		<van @click="toggle">
			<van-list class="list" v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
				finished-text="我是有底线的" @load="onLoadList">
				<div class="card-body">
					<van-swipe-cell v-for="(item,index) in goodsList" :key="index">
						<van-card :title="item.name" class="goods-card" :thumb="imageUrl+item.image" />
						<template #right>
							<van-button square text="修改" @click="toUrl('/cate/add',{id: item.id})" type="primary"
								class="card-button" />
							<van-button square text="删除" @click="delHandel(item)" type="danger" class="card-button" />
						</template>
					</van-swipe-cell>
				</div>
			</van-list>
		</van>
	</div>
</template>
<script>
	import {
		cateList,
		cateDel
	} from "@/api/cate"
	import {
		toUrl
	} from "@/utils/tools";
	import {
		Dialog,
		Toast
	} from "vant";
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				list: [],
				imageUrl: http.imageUrl,
				search: {
				  name: '',
				  page: 1,
				  limit: 15
				},
				// state: {
				//   loading: true,
				//   finished: false
				// },
				name: '',
				limit: 15, // 每页条数
				page: 1, // 页码
				type: 1,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
			}
		},
		mounted() {
			this.onLoad()
		},
		methods: {
			reload() {
			  this.list = []
			  this.search.page = 1
			  this.search.limit = 15
			  this.onLoad()
			},
			onLoad() {
				cateList(this.search).then((res) => {
					console.log(res)
					if (res.data.data.length === 0) {
						// 加载结束
						this.upFinished = true
						return
					}
					if (res.data.data.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = res.data.data
					} else {
						this.goodsList = this.goodsList.concat(res.data.data)
					}
				})
			},
			toUrl(path, param = null) {
				toUrl(path, param)
			},
			delHandel(item) {
				Dialog.confirm({
					message: '确定删除' + item.name + '分类吗？',
				}).then(() => {
					cateDel({
						'id': item.id
					}).then((res) => {
						Toast.success(res.msg);
						this.reload()
					})
				})
			},
			onLoadList() {
				this.search.page++;
				this.onLoad()
			}
		}
	}
</script>
<style lang="less">
	.cate {
		min-height: calc(100vh);

		.search {
			width: 60px;
			text-align: center;
			border: 1px solid #07c160;
			border-radius: 10px;
			color: #07c160;
		}

		.header {
			position: fixed;
			width: 100%;
			z-index: 1;
		}

		.list {
			margin: 0 .5rem;
			border-radius: .5rem;
			padding: 4rem 0 1rem 0;
		}

		.card-body {
			border-radius: .5rem;
			overflow: hidden;
		}

		.card-button {
			height: 100%;
		}

		.van-card__thumb {
			width: 3.6rem;
			height: 3.6rem;
		}

		.van-card__content {
			min-height: 3.6rem;
		}

		.van-card__title {
			max-height: 50px;
			font-weight: 500;
			line-height: 60px;
			padding-left: 1rem;
		}
	}
</style>
